<template>
  <div>
    <v-container class="mt-8 mx-2">
      <v-col>
        <v-row align="center">
          <v-btn @click="goBack" icon elevation="0">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <h3 class="pl-2">Route planen</h3>
        </v-row>
        <v-row align="center" justify="space-between" class="mt-4">
          <h4>{{ optimalRoutes.length }} Wegpunkte</h4>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
                Optionen
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title
                  @click="copyToClipboard(generateMultiStepGoogleMapsLink)"
                >
                  <v-icon>mdi-content-copy</v-icon>
                  Route kopieren
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="openGoogleMaps">
                  <v-icon>mdi-open-in-new</v-icon>
                  in Google Maps öffnen
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="reset">
                  <v-icon color="red lighten-1">
                    mdi-map-marker-remove-variant
                  </v-icon>
                  alle Wegpunkte löschen
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-col>
    </v-container>

    <v-container v-if="optimalRoutes.length > 0">
      <v-list-item
        two-line
        v-for="(route, index) in optimalRoutes"
        :key="index"
      >
        <v-list-item-icon>
          <img :src="route.icon" alt="" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <strong>
              {{ index + 1 }}
            </strong>
            {{ route.label }}
          </v-list-item-title>
          <v-list-item-subtitle>{{getKennzeichen(route)}}{{ getAddress(route) }}</v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-icon size="14">mdi-tag</v-icon>
            {{ route.mainCategory }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <!--<v-list-item v-if="index > 0">
                <v-list-item-title @click="setStartpoint(route)">
                  <v-icon>mdi-map-marker-down</v-icon>
                  Als Startpunkt festlegen
                </v-list-item-title>
              </v-list-item>-->
              <v-list-item>
                <v-list-item-title @click="remove(route)">
                  <v-icon color="red lighten-1">
                    mdi-map-marker-remove-variant
                  </v-icon>
                  Wegpunkt entfernen
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </v-container>

    <v-dialog v-model="confirmDeleteDialog" max-width="500px">
      <v-card v-if="aboutToDelete">
        <v-card-title class="headline">Wegpunkt entfernen</v-card-title>
        <v-card-text>
          Sicher, dass Sie den Wegpunkt
          <strong>{{ aboutToDelete.label }} </strong> entfernen möchten?
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="confirmDelete">ja</v-btn>
          <v-btn color="secondary" text @click="cancelDelete"
            >nein, abbrechen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmStartpointDialog" max-width="500px">
      <v-card v-if="aboutToStartWith">
        <v-card-title class="headline">Neuen Startpunkt festlegen</v-card-title>
        <v-card-text>
          Möchten Sie ihre Route mit
          {{ aboutToStartWith.label }} beginnen?</v-card-text
        >
        <v-card-actions>
          <v-btn color="primary" text @click="confirmStartpoint">ja</v-btn>
          <v-btn color="secondary" text @click="cancelStartpoint"
            >nein, abbrechen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmResetDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Alle Wegpunkt löschen?</v-card-title>
        <v-card-text>
          Sicher, dass Sie alle Wegpunkte löschen möchten?
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="confirmReset">ja</v-btn>
          <v-btn color="secondary" text @click="cancelReset"
            >nein, abbrechen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Draggable from "vuedraggable";
import { mapState } from "vuex";
export default {
  components: {
    // Draggable,
  },
  computed: {
    ...mapState("poi", ["routes"]),
    optimalRoutes() {
      const objects = [...this.routes];
      if (objects.length === 0) return [];

      let currentPoint = objects[0];
      const route = [currentPoint];
      const unvisitedObjects = objects.slice(1);

      while (unvisitedObjects.length > 0) {
        const nearestObject = unvisitedObjects.reduce((nearest, object) => {
          const distance = this.calculateDistance(
            currentPoint.geometry.coordinates,
            object.geometry.coordinates
          );
          const nearestDistance = this.calculateDistance(
            currentPoint.geometry.coordinates,
            nearest.geometry.coordinates
          );
          return distance < nearestDistance ? object : nearest;
        });

        route.push(nearestObject);
        unvisitedObjects.splice(unvisitedObjects.indexOf(nearestObject), 1);
        currentPoint = nearestObject;
      }
      return route;
    },
    generateMultiStepGoogleMapsLink() {
        // `google.navigation:q=${geometry.coordinates[1]},${geometry.coordinates[0]}&waypoints=50.943389842247505%2C6.955973131972474%7C51.220543124877146%2C6.791892235259009`
      const waypoints = this.optimalRoutes.map((object) =>
        object.geometry.coordinates.reverse()
      );
      if(this.isMobile) {
        const baseUrl = 'google.navigation:'
        const endDestinationPoint = waypoints.pop()
        const endDestination = `q=${this.getCorrectedCoordinate(endDestinationPoint[0],endDestinationPoint[1])}`
        let waypointString=''
        if(waypoints && waypoints.length > 0) {
            waypointString += `&waypoints=`
            waypointString += waypoints.map(waypoint=>`${this.getCorrectedCoordinate(waypoint[0],waypoint[1])}`).join('|')
        }
        return baseUrl + endDestination + waypointString
      } 
      const baseUrl='https://maps.google.de/maps?daddr='
      let waypointString=''
      if (waypoints && waypoints.length > 0) {
        waypointString = waypoints.map(waypoint=>`${this.getCorrectedCoordinate(waypoint[1],waypoint[0])}`).join('+to:')
      }
      return baseUrl + waypointString
    //  const start = waypoints.shift();
    //  const end = waypoints.pop();
    //  const joinedWaypoints = waypoints;

    //  console.log(start, end, joinedWaypoints);

    //  let baseUrl = "https://www.google.com/maps/dir/?api=1";
    //  let origin = `&origin=${this.getCorrectedCoordinate(start[1],start[0])}`;
    //  let destination = `&destination=${this.getCorrectedCoordinate(end[0],end[1])}`;
    //  let travelMode = "&travelmode=driving";

    //  let waypointString = "";
    //  if (joinedWaypoints && joinedWaypoints.length > 0) {
    //    if(this.isMobile) {
    //        waypointString = `&waypoints=${this.getCorrectedCoordinate(start[1],start[0])}|`
    //        waypointString += joinedWaypoints
    //            .map((waypoint) => `${this.getCorrectedCoordinate(waypoint[0],waypoint[1])}`)
    //            .join("|");
    //    } else {
    //        waypointString =
    //          "&waypoints=" +
    //          joinedWaypoints
    //            .map((waypoint) => `${this.getCorrectedCoordinate(waypoint[0],waypoint[1])}`)
    //            .join("|");
    //    }
    //  }
    //  if (this.isMobile) {
    //    if(joinedWaypoints && joinedWaypoints.length > 0) {
    //        return `google.navigation:q=${this.getCorrectedCoordinate(end[0],end[1])}${waypointString}`
    //    } else {
    //        return `google.navigation:q=${this.getCorrectedCoordinate(end[0],end[1])}&waypoints=${this.getCorrectedCoordinate(start[0],start[1])}`
    //    }
    //  }
    //  let newWaypointString=''
    //  if(joinedWaypoints &&joinedWaypoints.length>0) {
    //    newWaypointString=joinedWaypoints.map(waypoint=>`${waypoint[0]},${waypoint[1]}`).join('+to:')
    //  }
    //  const testURL = `https://maps.google.com/maps?saddr=${start[1]},${start[0]}&daddr=&amp;ll=`
    //  return baseUrl + origin + destination + waypointString + travelMode;
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|MAPP/i.test(
        navigator.userAgent
      );
    },
  },
  data() {
    return {
      aboutToDelete: null,
      aboutToStartWith: null,
      confirmResetDialog: false,
      confirmStartpointDialog: false,
      confirmDeleteDialog: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push("/");
    },
    copyToClipboard(text) {
      const el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$store.commit('showSnackbar',{
        message: 'Die Route wurde in Zwischenablage gespeichert.', 
        color: 'green'
      })
    },
    reset() {
      this.confirmResetDialog = true;
    },
    confirmReset() {
      this.$store.dispatch("poi/resetRoutenplanner");
      this.confirmResetDialog = false;
    },
    cancelReset() {
      this.confirmResetDialog = false;
    },
    getAddress(route) {
      let address = "";
      if (route && route.properties) {
        address += route.properties.Straßenname
          ? route.properties.Straßenname
          : "";
        address += route.properties.Hausnummer
          ? " " + route.properties.Hausnummer
          : "";
      }
      return address;
    },
    getKennzeichen(route){
        if(route.properties.Stationskennzeichen) {
            return route.properties.Stationskennzeichen + ' - '
        }
        if(route.properties.Schaltstellenkennzeichen) {
            return route.properties.Schaltstellenkennzeichen + ' - '
        }
        if(route.properties.Schlüsselbezeichnung) {
            return route.properties.Schlüsselbezeichnung + ' - '
        }
        if(route.anlagenKuerzel) {
            return route.anlagenKuerzel + ' - '
        }
        return ''
    },
    calculateDistance(coord1, coord2) {
      const [x1, y1] = coord1;
      const [x2, y2] = coord2;
      return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    },
    openGoogleMaps() {
      const link = this.generateMultiStepGoogleMapsLink;
      window.open(link, "_blank");
    },
    setStartpoint(route) {
      this.$store.commit("poi/setStartpoint", route);
    },
    cancelStartpoint() {
      this.confirmStartpointDialog = false;
      this.aboutToStartWith = null;
    },
    confirmStartpoint() {
      this.confirmStartpointDialog = false;
      this.$store.commit("poi/setStartpoint", this.aboutToStartWith);
      this.$nextTick(() => {
        this.aboutToStartWith = null;
      });
    },
    remove(route) {
      this.aboutToDelete = route;
      this.confirmDeleteDialog = true;
    },
    confirmDelete() {
      this.confirmDeleteDialog = false;
      this.$store.commit("poi/removeRoute", this.aboutToDelete);
      this.$nextTick(() => {
        this.aboutToDelete = null;
      });
    },
    cancelDelete() {
      this.confirmDeleteDialog = false;
      this.aboutToDelete = null;
    },
    getCorrectedCoordinate(lat,lon) {
        if(lat>45 && lat<55) {
            return `${lat},${lon}`
        } else {
            return `${lon},${lat}`
        }
    }
  },
};
</script>

<style lang="scss" scoped>
.v-list--two-line .v-list-item .v-list-item__icon,
.v-list-item--two-line .v-list-item__icon {
  margin: 0;
  margin: 0.5rem 0.5rem 0 0;
}
img {
  max-width: 3.25rem;
  height: auto;
}
</style>
